import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/effect-cards";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import pic1 from "./Assets/1.jpg";
import pic2 from "./Assets/2.jpg";
import pic3 from "./Assets/3.jpg";
import pic4 from "./Assets/4.jpg";
import pic5 from "./Assets/5.jpg";
import pic6 from "./Assets/6.jpg";
import pic7 from "./Assets/7.jpg";
import pic8 from "./Assets/8.jpg";
import pic9 from "./Assets/9.jpg";
import pic10 from "./Assets/10.jpg";
import "./style.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { EffectCards } from "swiper/modules";
export default function Slider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={pic1} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic2} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic3} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic4} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic5} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic6} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic7} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic8} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic9} />
        </SwiperSlide>
        <SwiperSlide>
          <img src={pic10} />
        </SwiperSlide>
      </Swiper>
    </>
  );
}
